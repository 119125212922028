<template>
  <div>
      <div class="container">
          <el-card>
              <div slot="header">
                  <h3>阿里云物联平台MQTT密码计算</h3>
                  <h3>填入设备信息：</h3>
                </div>
              <div>
                    <div class="box">
                        productKey:<input type="text" id="productKey" name="productKey"></input><br/>
                    </div>
                    <div class="box">
                        deviceName:<input type="text" id="deviceName" name="deviceName"></input><br/>
                    </div>
                    <div class="box">
                        deviceSecret:<input type="text" id="deviceSecret" name="deviceSecret" style="width:300px"></input><br/>
                    </div>           
                    <div class="box">
                        timestamp:<input type="text" id="timestamp" name="timestamp"></input><br>
                    </div>  
                    <div class="box">
                        clientId:<input type="text" id="clientId" name="clientId" style="width:300px"></input><br/>
                    </div>  
                    <div class="box">
                        method:<select id="signMethod" name="signMethod">
                        <option value="hmacmd5">hmacmd5</option>
                        <option value="hmacsha1" selected>hmacsha1</option>
                        </select><br/><br/>      
                        点击这里得出密码：<button id="submit" name="submit" @click="onSign()">Generate</button>
                        <br/>
                    </div>
                
                    
                   
              </div>
          </el-card>
          <el-card>
                    <div slot="header"><h3>计算结果：</h3></div>
                    <div>
                         <!-- 点击这里求结果：<input type="button" name="Submit" value="按钮" onclick="show();" /> -->
                    <div id="show"></div>
                    <div id="show1"></div>
                    <div id="show2"></div>
                    <div id="show4"></div>
                    <div id="show3"></div>
                    用户密码password:  <br/><input type="text" id="password" name="password" style="width:600px"></input>
                    </div>
            </el-card>    
        </div>
        <Footer/>
  </div>
</template>

<script>
import {hex_hmac_sha1} from '../assets/js/hex_hmac_sha1'
import {hex_hmac_md5} from '../assets/js/hex_md5'
import Footer from '@/components/footer'
export default {
data() {
return {
 
}
},
mounted() {

},

methods: {
    onSign() {
        var pk = document.getElementById("productKey").value;
        var dn = document.getElementById("deviceName").value;
        var ds = document.getElementById("deviceSecret").value;
        var ts = document.getElementById("timestamp").value;
        var ci = document.getElementById("clientId").value;
        var sm = document.getElementById("signMethod").value;
        var pw = document.getElementById("password");
        if (pk == null || pk == "" || dn == null || dn == "" 
                || ds == null || ds == "" || ci == null || ci == "") {
            alert("productKey,deviceName,deviceSecret,clientId can not be empty");
            return;
        }
        var options = {
            productKey: pk,
            deviceName: dn,
            timestamp: ts,
            clientId: ci
        }
        if (ts == null || ts == "") {
            options = {
                productKey: pk,
                deviceName: dn,
                clientId: ci
            }
        }
        var keys = Object.keys(options).sort();
        // 按字典序排序
        keys = keys.sort();
        var list = [];
        keys.forEach(function(key) {
            list.push(key + options[key]);
        });
        var contentStr = list.join('');
        var sign="";
        if (sm == "hmacmd5") {
            sign = hex_hmac_md5(ds, contentStr);
        } else if (sm == "hmacsha1") {
            sign = hex_hmac_sha1(ds, contentStr);
        } else {
            alert("method is invalid");
        }
        pw.value = sign.toUpperCase();
		
		var a1=document.getElementById("productKey");
        var a2=document.getElementById("deviceName");
        var a3=document.getElementById("Port");
        var a4=document.getElementById("clientId");
        var a5=document.getElementById("signMethod");
        var a6=document.getElementById("timestamp");

        var show=document.getElementById("show");
        show.innerHTML="域名Domain Name:"+"<br/>"+productKey.value+".iot-as-mqtt.cn-shanghai.aliyuncs.com";
        show1.innerHTML="端口Port:"+"<br/>1883";
        show2.innerHTML="客户端编号ClientId(MQTT):"+"<br/>"+clientId.value+"|securemode=3,signmethod="+signMethod.value+",timestamp="+timestamp.value+"|";
       	show4.innerHTML="客户端编号ClientId(MQTTS TLS):"+"<br/>"+clientId.value+"|securemode=2,signmethod="+signMethod.value+",timestamp="+timestamp.value+"|";
        show3.innerHTML="用户名Username:"+"<br/>"+deviceName.value+"&"+productKey.value;
    }
},
components:{
    Footer
},
}

</script>

<style scoped>
.container{
    margin-top: 50px;
    font-size: 20px;
    line-height: 30px;
    min-height: 800px;
}
.container input{
    font-size: 20px;
}
.box{
    margin: 1rem 1rem 1rem 1rem;
}
</style>